import { Component, OnInit } from '@angular/core';
import {ModalController, NavParams} from '@ionic/angular';
import {DomSanitizer} from '@angular/platform-browser';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss'],
})
export class ModalComponent implements OnInit {

  type: string;
  data: any;
  errorMessage = '';

  invite = {
    code: ''
  };

  welcomeStep = 0;
  welcomeVideo;
  welcomeNavBulls = [...new Array(7).keys()];
  catalogPhotoStatus = 0;
  isCopySuccesfull: boolean;
  isCopyFailed: boolean;
  currentEnv = environment.auth0CallBackUrl;
  srcTrusted: any = null;
  srcURL = '';

  constructor(
    private modalController: ModalController,
    private navParams: NavParams,
    private domSanitizer: DomSanitizer) {
    this.type = this.navParams.get('type');
    this.data = this.navParams.get('data');
    if(this.type === 'welcome') {
      this.welcomeVideo = this.domSanitizer.bypassSecurityTrustResourceUrl(
        'https://player.vimeo.com/video/695524924?h=f75e8a1f79&badge=0&autopause=0&player_id=0&app_id=58479'
      );
    }
    if(this.type === 'previewPDF') {
      this.srcURL = environment.mediaPath + '/?file=' + encodeURIComponent(this.data.file) + '#search=' + this.data.query + '&phrase=true';
      this.srcTrusted = this.domSanitizer.bypassSecurityTrustResourceUrl(this.srcURL);
    }
  }

  submitInviteForm() {
    this.modalController.dismiss(this.invite);
  }

  async deletePhoto(index = 0) {
    await this.modalController.dismiss({id: index});
  }

  async myDismiss(value = 0) {
    await this.modalController.dismiss(value);
  }

  async markAsDone() {
    await this.modalController.dismiss(1);
  }

  backWelcome() {
    this.welcomeStep = 0;
  }

  printMat() {
    window.open('https://qr-scan.it/g/q5GaeF3OIn', '_blank');
  }

  nextWelcomeStep() {
    this.welcomeStep = this.welcomeStep + 1;
  }

  useCatalogPhoto() {
    this.data.sources.push(this.data.source);
    if(this.data?.instCreate) {
      this.takeMorePhotosLater();
    } else {
      this.catalogPhotoStatus = 2;
    }
  }

  submitPhotos() {
    this.catalogPhotoStatus = 1;
  }

  async takeMorePhotosLater() {
    this.data.status = 1;
    await this.modalController.dismiss(this.data);
  }

  async takeMorePhotos() {
    this.data.status = 2;
    await this.modalController.dismiss(this.data);
  }

  async retakeCatalogPhoto() {
    this.data.status = 3;
    await this.modalController.dismiss(this.data);
  }

  async extraCatalogPhoto() {
    this.data.status = 4;
    await this.modalController.dismiss(this.data);
  }

  fallbackCopyTextToClipboard(text) {
    const textArea = document.createElement('textarea');
    textArea.value = text;
    textArea.style.top = '0';
    textArea.style.left = '0';
    textArea.style.position = 'fixed';
    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();

    try {
      const successful = document.execCommand('copy');
      const msg = successful ? 'successful' : 'unsuccessful';
      this.isCopySuccesfull = true;
      this.cleanCopyStatus();
      console.log('Fallback: Copying text command was ' + msg);
    } catch (err) {
      this.isCopyFailed = true;
      this.cleanCopyStatus();
      console.error('Fallback: Oops, unable to copy', err);
    }

    document.body.removeChild(textArea);
  }

  copyTextToClipboard(text) {
    if (!navigator.clipboard) {
      this.fallbackCopyTextToClipboard(text);
      return;
    }
    navigator.clipboard.writeText(text).then(() => {
      this.isCopySuccesfull = true;
      this.cleanCopyStatus();
    }, (err) => {
      console.error('Async: Could not copy text: ', err);
      this.isCopyFailed = true;
      this.cleanCopyStatus();
    });
  }

  cleanCopyStatus() {
    setTimeout(() => {
      this.isCopySuccesfull = false;
      this.isCopyFailed = false;
    }, 5000);
  }

  ngOnInit() {
    console.log(this.data);
  }

}
