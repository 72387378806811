import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import {AuthConfig, AuthHttpInterceptor, AuthModule} from '@auth0/auth0-angular';
import { environment } from '../environments/environment';
import {HTTP_INTERCEPTORS, HttpClient, HttpClientModule} from '@angular/common/http';
import {Capacitor} from '@capacitor/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {QRCodeModule} from 'angularx-qrcode';
import {NetworkInterceptor} from './interceptors/network.interceptor';
import {CookieService} from 'ngx-cookie-service';

export const languageLoader = (http: HttpClient) => new TranslateHttpLoader(http, './assets/i18n/', '.json');

const config: AuthConfig = {
  domain: environment.auth0Domain,
  clientId: environment.auth0ClientID,
  authorizationParams: {
    redirect_uri: Capacitor.isNativePlatform()
      ? environment.appPackageID + '://' + environment.auth0Domain + '/capacitor/' + environment.appPackageID + '/callback'
      : environment.auth0CallBackUrl,
    audience: environment.auth0Audience,
    scope: 'openid profile offline_access read:current_user update:current_user_metadata',
  },
  useRefreshTokens: true,
  useRefreshTokensFallback: true,
  cacheLocation: 'localstorage',
  httpInterceptor: {
    allowedList: [
      {
        uri: environment.apiPath + '/*'
      },
      {
        uri: environment.apiPathV2 + '/*'
      }
    ]
  }
  /*useRefreshTokens: true*/
};

@NgModule({
    declarations: [AppComponent],
    imports: [
        BrowserModule,
        HttpClientModule,
        IonicModule.forRoot({
          innerHTMLTemplatesEnabled: true
        }),
        AppRoutingModule,
        QRCodeModule,
        AuthModule.forRoot(config),
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: (languageLoader),
                deps: [HttpClient]
            }
        }),
    ],
    providers: [
        CookieService,
        { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
        { provide: HTTP_INTERCEPTORS, useClass: AuthHttpInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: NetworkInterceptor, multi: true}
    ],
    bootstrap: [AppComponent],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule {}
