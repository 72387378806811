import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import {Capacitor} from '@capacitor/core';


if (Capacitor.isNativePlatform()) {
    const orientation: any = window.screen.orientation;
    if (orientation && typeof orientation.lock === 'function') {
        orientation.lock('portrait').catch((error) => {
            console.error("Failed to lock orientation:", error);
        });
    } else {
        console.error("Orientation lock function is not available");
    }
}

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.log(err));
